<template>
  <div class="app-container">
    <el-card
      shadow="never"
      class="filter-container-card"
    >
      <!-- filter -->
      <div class="filter-container">
        <div class="filter-line">
          <el-select
            v-model="filter.isActive"
            style="width: 120px"
            class="filter-item"
            placeholder="Is active"
            clearable="clearable"
            @change="onFilter"
          >
            <el-option
              v-for="item in [
                { key: true, label: 'Active' },
                { key: false, label: 'Not active' }
              ]"
              :key="item.key"
              :label="item.label"
              :value="item.key"
            />
          </el-select>

          <el-select
            v-model="filter.language"
            style="width: 160px"
            class="filter-item"
            placeholder="Language"
            clearable="clearable"
            multiple
            @change="onFilter"
          >
            <el-option
              v-for="item in langOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>

          <el-select
            v-model="filter.categoryUids"
            style="width: 160px"
            class="filter-item"
            placeholder="Category"
            clearable="clearable"
            multiple
            @change="onFilter"
          >
            <el-option
              v-for="item in catOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>

          <el-input
            v-model="filter.search"
            placeholder="Search"
            style="width: 220px"
            class="filter-item"
            clearable
            @clear="onFilter"
            @keyup.enter.native="onFilter"
          />

          <el-button
            type="primary"
            icon="el-icon-search"
            class="filter-item"
            :loading="listLoading"
            title="Search"
            @click="onFilter"
          />

          <el-button
            icon="el-icon-circle-close"
            class="filter-item reset-filter"
            :loading="listLoading"
            title="Reset Filter"
            @click="onFilterReset"
          />
        </div>

        <div class="filter-line">
          <el-divider />

          <el-button
            v-permission="['admin']"
            class="filter-item"
            icon="el-icon-folder-opened"
            :loading="listLoading"
            @click="handleCategoriesList"
          >
            Categories
          </el-button>

          <el-button
            v-permission="['admin']"
            class="filter-item margin-left-10"
            type="primary"
            icon="el-icon-circle-plus-outline"
            :loading="listLoading"
            @click="handleNewPost"
          >
            New Post
          </el-button>
        </div>
      </div>
    </el-card>

    <!-- table -->
    <el-table
      ref="table"
      v-loading="listLoading"
      :data="list"
      border
      :max-height="maxHeight"
      @sort-change="handleSort"
    >
      <el-table-column
        label="Title"
        prop="title"
        min-width="250"
      >
        <template slot-scope="{ row }">
          <span
            class="link-type"
            @click.stop="handlePostEdit(row.postUid)"
          >
            {{ getTitle(row.content) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        label="Categories"
        width="240"
      >
        <template slot-scope="{ row }">
          <span>
            <el-tag
              v-for="item in getPostCategories(row.categoryUids)"
              :key="item.uid"
              :type="item.isActive ? 'info' : 'danger'"
              style="cursor: pointer; margin-right: 3px;"
              @click="setCategoryFilter(item.uid)"
            >
              {{ item.name }}
            </el-tag>
          </span>
        </template>
      </el-table-column>

      <el-table-column
        label="Languages"
        width="200"
      >
        <template slot-scope="{ row }">
          <span>
            {{ joinLanguages(row.content) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        label="Active"
        width="80"
      >
        <template slot-scope="{ row }">
          <el-tag :type="row.isActive ? 'success' : 'danger'">
            {{ row.isActive ? 'Yes' : 'No' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        label="Scheduled At"
        width="150"
        sortable="custom"
        prop="scheduledAt"
      >
        <template slot-scope="{ row }">
          <span
            :style="{ color: isScheduled(row.scheduledAt) ? '#f56c6c' : '#5fb434' }"
          >
            {{ row.scheduledAt | formatDate }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        label="Created At"
        width="150"
        sortable="custom"
        prop="createdAt"
      >
        <template slot-scope="{ row }">
          <span>{{ row.createdAt | formatDate }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Updated At"
        width="150"
        sortable="custom"
        prop="updatedAt"
      >
        <template slot-scope="{ row }">
          <span>{{ row.updatedAt | formatDate }}</span>
        </template>
      </el-table-column>

      <el-table-column
        v-permission="['admin']"
        align="center"
        width="130"
      >
        <template
          slot-scope="{ row }"
        >
          <el-button
            v-permission="['admin']"
            type="primary"
            size="mini"
            circle
            plain
            title="Edit"
            @click.stop="handlePostEdit(row.postUid)"
          >
            <i class="far fa-edit"></i>
          </el-button>

          <el-button
            v-permission="['admin']"
            type="danger"
            size="mini"
            circle
            plain
            title="Delete"
            @click.stop="handlePostDelete(row.postUid)"
          >
            <i class="far fa-trash"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-show="total > 0"
      :pager-count="5"
      class="pagination"
      background
      layout="total, prev, pager, next, sizes"
      :current-page.sync="listQuery.pageNumber"
      :page-size.sync="listQuery.pageSize"
      :total="total"
      @size-change="getList"
      @current-change="getList"
    />

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      top="25px"
    >
      <div>
        <post-details
          :is-edit-mode="isEditMode"
          :post-uid="activePostUid"
          :visible.sync="dialogVisible"
          @update="getList"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>

import { getAllPostCategories } from '@/api/postCategory';
import { listPosts, deletePost } from '@/api/post';
import { getAllLanguages } from '@/api/language';
import maxHeightMixin from '@/mixins/maxHeight';
import linkViewMixin from '@/mixins/linkView';
import permission from '@/directive/permission';
import PostDetails from './components/postDetails';

export default {
  name: 'PostList',
  directives: {
    permission,
  },
  components: {
    PostDetails,
  },
  mixins: [maxHeightMixin, linkViewMixin],
  data() {
    const pageSize = +localStorage.getItem('defPageSize');
    const defSortBy = 'scheduledAt';
    const defSortDir = 'desc';

    return {
      list: null,
      total: 0,
      listLoading: true,
      catOptions: [],
      postCategories: [],
      langOptions: [],
      defSortBy,
      defSortDir,
      filter: {
        search: '',
        isActive: '',
        categoryUids: '',
        language: '',
      },
      listQuery: {
        pageNumber: 1,
        pageSize: pageSize || 20,
        sortBy: defSortBy,
        sortDir: defSortDir,
      },
      dialogVisible: false,
      isEditMode: false,
      activePostUid: null,
    };
  },
  watch: {
    $route(to) {
      if (to.name === 'PostList') {
        this.getList();
      }
    },
  },
  async created() {
    await this.getList();
  },
  activated() {
    this.getList(true);
  },
  methods: {
    async getList(refresh = false) {
      try {
        this.listLoading = true;

        if (!refresh) {
          this.list = [];
          this.listQuery.pageNumber = 1;
        }

        await this.getAllCategories();
        await this.getLanguages();

        const data = await listPosts(this.listQuery);
        const { items, pageInfo } = data.page;

        this.list = items;
        this.total = pageInfo.itemsAvailable;
        this.listLoading = false;

        localStorage.setItem('defPageSize', this.listQuery.pageSize);
      } catch (e) {
        this.listLoading = false;

        throw e;
      }
    },
    async getAllCategories() {
      try {
        const data = await getAllPostCategories();
        this.catOptions = data.categories.map((item) => ({
          value: item.categoryUid,
          label: item.content[0].name,
        }));

        this.postCategories = data.categories;
      } catch (e) {
        this.catOptions = [];
      }
    },
    handleSort({ prop, order }) {
      if (order !== null) {
        this.listQuery.sortBy = prop;
        this.listQuery.sortDir = order === 'ascending' ? 'asc' : 'desc';
      } else {
        this.listQuery.sortBy = this.defSortBy;
        this.listQuery.sortDir = this.defSortDir;
      }

      this.getList();
    },
    handleNewPost() {
      this.activePostUid = null;
      this.isEditMode = false;
      this.dialogVisible = true;
    },
    handlePostEdit(postUid) {
      this.activePostUid = postUid;
      this.isEditMode = true;
      this.dialogVisible = true;
    },
    handlePostDelete(postUid) {
      this.$confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        deletePost({ postUid }).then(() => {
          this.$message({
            type: 'success',
            message: 'Delete successful',
          });
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled',
        });
      });
    },
    onFilter() {
      const filter = Object.keys(this.filter).reduce((acc, key) => {
        if (this.filter[key] !== '') {
          acc[key] = this.filter[key];
        }

        return acc;
      }, {});

      this.listQuery.filter = JSON.stringify(filter);
      this.getList();
    },
    onFilterReset() {
      this.filter = {
        search: '',
        isActive: '',
        categoryUids: '',
        language: '',
      };

      this.setDefaultFilter();
      this.getList();
    },
    setDefaultFilter() {
      this.listQuery.filter = JSON.stringify({});
    },
    handleCategoriesList() {
      this.$router.push({ name: 'CategoryList' });
    },
    getTitle(content) {
      return content[0].title.length > 100
        ? `${content[0].title.slice(0, 100)}...`
        : content[0].title;
    },
    getPostCategories(categoryUids) {
      return categoryUids.map((uid) => {
        const cat = this.postCategories.find((item) => item.categoryUid === uid);

        return {
          name: cat?.content[0].name || 'N/A',
          uid,
          isActive: cat?.isActive || false,
        };
      });
    },
    async getLanguages() {
      try {
        const data = await getAllLanguages();
        this.langOptions = data.languages.map((item) => ({
          value: item.langCode,
          label: item.langName,
        }));
      } catch (e) {
        this.langOptions = [];
      }
    },
    joinLanguages(content) {
      if (!content || !content.length) {
        return 'N/A';
      }

      return content.map((item) => item.langCode.toLocaleUpperCase()).join(', ');
    },
    setCategoryFilter(categoryUid) {
      this.filter.categoryUids = [categoryUid];
      this.onFilter();
    },
    isScheduled(date) {
      return new Date(date) > new Date();
    },
  },
};
</script>
