import Vue from 'vue';

export function listPosts(params) {
  return Vue.$http({
    url: '/posts/listPosts',
    method: 'get',
    params,
  });
}

export function getPost(params) {
  return Vue.$http({
    url: '/posts/getPost',
    method: 'get',
    params,
  });
}

export function updatePost(data) {
  return Vue.$http({
    url: '/posts/updatePost',
    method: 'post',
    data,
  });
}

export function createPost(data) {
  return Vue.$http({
    url: '/posts/createPost',
    method: 'post',
    data,
  });
}

export function deletePost(data) {
  return Vue.$http({
    url: '/posts/deletePost',
    method: 'post',
    data,
  });
}

export function generateRelativeUrl(params) {
  return Vue.$http({
    url: '/posts/generateRelativeUrl',
    method: 'get',
    params,
  });
}
