<template>
  <div class="flex-form">
    <el-form
      ref="postForm"
      v-loading="loading"
      :model="postForm"
      label-width="170px"
      :rules="rules"
      autocomplete="off"
    >
      <el-form-item>
        <h2 v-if="isEditMode">
          Edit Post
        </h2>
        <h2 v-else-if="!isEditMode">
          Add Post
        </h2>
      </el-form-item>

      <el-form-item
        v-if="isEditMode"
        label="Uid"
      >
        {{ postUid }}
      </el-form-item>

      <el-form-item
        label="Active"
        prop="active"
      >
        <el-switch
          v-model="postForm.isActive"
        />
      </el-form-item>

      <el-form-item
        label="Categories"
        prop="categoryUids"
      >
        <el-select
          v-model="postForm.categoryUids"
          multiple
          placeholder="Select"
        >
          <el-option
            v-for="item in catOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="Schedule At"
        prop="scheduledAt"
      >
        <el-date-picker
          v-model="postForm.scheduledAt"
          type="date"
          placeholder="Pick a date"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item
        label="Cover"
      >
        <upload-media
          ref="coverImage"
          :image-url="coverUrl"
          :params="{ contentType: 'POST_COVER' }"
          @success="handleCoverUploadSuccess"
          @remove="handleCoverUploadRemove"
        />
      </el-form-item>

      <el-tabs
        v-model="activeLang"
        type="card"
        closable
        @edit="handleTabsEdit"
      >
        <el-tab-pane
          v-for="(item, index) in postForm.content"
          :key="item.index"
          :label="langName(item.langCode)"
          :name="item.langCode"
        >
          <el-form-item
            :label="`Title [${item.langCode}]`"
            :prop="'content.' + index + '.title'"
          >
            <el-input
              v-model="postForm.content[index].title"
              @input="handleTitleInput(index, item.langCode)"
            />
          </el-form-item>

          <el-form-item
            :label="`Relative Url [${item.langCode}]`"
            :prop="'content.' + index + '.relativeUrl'"
          >
            <RelativeUrl
              v-model="postForm.content[index].relativeUrl"
              @generate-relative-url="handleRelativeUrl($event, index, postForm.content[index].title)"
              @set-relative-url="setRelativeUrl($event, index)"
            />
          </el-form-item>

          <el-form-item
            :label="`Content [${item.langCode}]`"
            :prop="'content.' + index + '.content'"
          >
            <Editor
              v-model="postForm.content[index].content"
            />
          </el-form-item>

          <el-form-item
            :label="`Meta Title [${item.langCode}]`"
            :prop="'content.' + index + '.metaTitle'"
          >
            <el-input v-model="postForm.content[index].metaTitle" />
          </el-form-item>

          <el-form-item
            :label="`Meta Description [${item.langCode}]`"
            :prop="'content.' + index + '.metaDescription'"
          >
            <el-input
              v-model="postForm.content[index].metaDescription"
              type="textarea"
              resize="none"
              :autosize="{ minRows: 2, maxRows: 8}"
            />
          </el-form-item>
        </el-tab-pane>
      </el-tabs>

      <el-form-item class="flex-form__action-buttons">
        <el-button
          type="primary"
          :loading="saving"
          @click="onSubmit"
        >
          Save
        </el-button>

        <el-button
          v-if="isEditMode"
          type="success"
          :loading="saving"
          @click="onSubmit(false)"
        >
          Apply
        </el-button>

        <el-button @click="onCancel">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  createPost,
  generateRelativeUrl,
  getPost,
  updatePost,
} from '@/api/post';
import { getAllLanguages } from '@/api/language';
import { getAllPostCategories } from '@/api/postCategory';
import { cloneDeep } from 'lodash';
import { makeUpdateObj } from '@/utils';
import UploadMedia from '@/components/UploadMedia';
import Editor from '@/components/Editor';
import RelativeUrl from '@/components/RelativeUrl';

export default {
  name: 'PostDetails',
  components: {
    UploadMedia,
    Editor,
    RelativeUrl,
  },
  props: {
    isEditMode: {
      default: false,
      type: Boolean,
    },
    postUid: {
      default: null,
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      catOptions: [],
      langOptions: [],
      postForm: {
        isActive: true,
        categoryUids: [],
        content: [],
        scheduledAt: null,
        coverMediaUid: null,
      },
      activeLang: '',
      relativeUrlEdited: {},
      post: {},
      rules: {
        categoryUids: [{
          required: true,
          trigger: 'blur',
          message: 'Category is required',
        }],
      },
    };
  },
  computed: {
    coverUrl() {
      return this.postForm?.media?.cover?.image?.location;
    },
  },
  watch: {
    visible() {
      if (this.postUid) {
        this.getPost(this.postUid);
      } else {
        this.initForm();
      }
    },
  },
  async created() {
        if (this.isEditMode) {
      await this.getPost(this.postUid);
    } else {
      await this.initForm();
    }
  },
  methods: {
    async initForm() {
      this.resetForm();

      await this.getLanguages();
      await this.getPostCategories();

      this.langOptions.forEach((item) => {
        this.postForm.content.push({
          langCode: item.value,
          title: '',
          relativeUrl: '',
          content: '',
          metaTitle: '',
          metaDescription: '',
        });
      });

      this.activeLang = this.langOptions[0].value;

      this.postForm.content.forEach((item, index) => {
        this.handleContentRules(index, item.langCode, item.title);
      });
    },
    langName(code) {
      const langName = this.langOptions.find((item) => item.value === code)?.label;
      const catName = this.postForm.content.find((item) => item.langCode === code)?.relativeUrl;

      if (!catName.trim()) {
        return `! ${langName}`;
      }

      return langName;
    },
    async getPost(postUid) {
      try {
        this.resetForm();
        this.loading = true;

        await this.getLanguages();
        await this.getPostCategories();

        const data = await getPost({ postUid });

        this.post = cloneDeep(data.post);
        this.postForm = data.post;

        // order content by langOptions
        this.postForm.content = this.langOptions.map((item) => {
          const content = this.postForm.content.find((c) => c.langCode === item.value);
          if (!content) {
            return {
              langCode: item.value,
              title: '',
              relativeUrl: '',
              content: '',
              metaTitle: '',
              metaDescription: '',
            };
          }

          return content;
        });

        this.activeLang = this.postForm.content
          .find((item) => item.title.trim())?.langCode || this.langOptions[0].value;

        this.postForm.content.forEach((item, index) => {
          this.handleContentRules(index, item.langCode, item.title);
        });

        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.onCancel();
      }
    },
    async getPostCategories() {
      try {
        const data = await getAllPostCategories();
        this.catOptions = data.categories.map((item) => ({
          value: item.categoryUid,
          label: item.content[0].name,
        }));
      } catch (e) {
        this.catOptions = [];
      }
    },
    async getLanguages() {
      try {
        const data = await getAllLanguages();
        this.langOptions = data.languages.map((item) => ({
          value: item.langCode,
          label: item.langName,
        }));
      } catch (e) {
        this.langOptions = [];
      }
    },
    async onEditSubmit(close) {
      this.$refs.postForm.validate(async (valid, errors) => {
        if (valid) {
          const updatedPost = makeUpdateObj(this.post, this.postForm);
          if (Object.keys(updatedPost).length > 0) {
            try {
              this.saving = true;
              updatedPost.postUid = this.postUid;

              await updatePost(updatedPost);

              this.$message({
                type: 'success',
                message: 'Data has been saved successfully',
              });

              this.saving = false;

              this.$emit('update');

              if (close) {
                this.close();
              }
            } catch (e) {
              // await this.getPost(this.postUid);

              this.saving = false;
            }
          }
        } else {
          this.$message({
            type: 'error',
            message: errors[Object.keys(errors)[0]][0].message,
          });
        }
      });
    },
    async onAddSubmit(close) {
      this.$refs.postForm.validate(async (valid, errors) => {
        if (valid) {
          try {
            this.saving = true;
            await createPost(this.postForm);

            this.$message({
              type: 'success',
              message: 'Post has been added successfully',
            });

            // this.resetForm();
            this.saving = false;
            // this.postUid = res?.post?.postUid;

            this.$emit('update');

            if (close) {
              this.close();
            }
          } catch (e) {
            this.saving = false;
          }
        } else {
          this.$message({
            type: 'error',
            message: errors[Object.keys(errors)[0]][0].message,
          });
        }
      });
    },
    async onSubmit(close = true) {
      if (this.isEditMode) {
        await this.onEditSubmit(close);
      } else {
        await this.onAddSubmit(close);
      }
    },
    resetForm() {
      this.relativeUrlEdited = {};
      this.postForm.content = [];
      this.postForm.media = {};
      this.post = {};

      this.$nextTick(() => {
        this.$refs.postForm.resetFields();
        this.$refs.coverImage.reset();
      });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
    handleCoverUploadSuccess(data) {
      this.postForm.coverMediaUid = data.mediaUid;
    },
    handleCoverUploadRemove() {
      this.postForm.coverMediaUid = null;
      this.postForm.media.cover = null;
    },
    handleContentRules(index, lang, value) {
      if (value.trim()) {
        this.addContentRules(index, lang);
      } else {
        this.removeStaticPageRules(index);
      }
    },
    addContentRules(index, lang) {
      if (this.rules[`content.${index}.title`] || index < 0) {
        return;
      }

      this.$set(this.rules, `content.${index}.title`, [{
        required: true,
        trigger: 'blur',
        message: `Title [${lang}] is required`,
      }]);
      this.$set(this.rules, `content.${index}.relativeUrl`, [{
        required: true,
        trigger: 'blur',
        message: `Relative Url [${lang}] is required`,
      }]);
      this.$set(this.rules, `content.${index}.content`, [{
        required: true,
        trigger: 'blur',
        message: `Content [${lang}] is required`,
      }]);

      setTimeout(() => {
        this.$refs.postForm.clearValidate();
      });
    },
    removeStaticPageRules(index) {
      this.$delete(this.rules, `content.${index}.title`);
      this.$delete(this.rules, `content.${index}.relativeUrl`);
      this.$delete(this.rules, `content.${index}.content`);
    },
    handleTabsEdit(targetName) {
      // remove content
      const index = this.postForm.content.findIndex((item) => item.langCode === targetName);

      this.postForm.content[index].title = '';
      this.postForm.content[index].relativeUrl = '';
      this.postForm.content[index].metaTitle = '';
      this.postForm.content[index].metaDescription = '';
      this.postForm.content[index].content = '';

      this.removeStaticPageRules(index);
    },
    async handleRelativeUrl(editRelativeUrl, index, value) {
      if (this.post?.content?.[index]?.relativeUrl && !editRelativeUrl) {
        return;
      }

      if (value && value.trim().length) {
        const data = await generateRelativeUrl({
          title: value,
          postUid: this.postUid,
          langCode: this.postForm.content[index].langCode,
        });

        if (data.relativeUrl) {
          this.postForm.content[index].relativeUrl = data.relativeUrl;
        }
      }
    },
    handleTitleInput(index, langCode) {
      this.handleContentRules(index, langCode, this.postForm.content[index].title);
      if (!this.relativeUrlEdited[index]) {
        this.handleRelativeUrl(false, index, this.postForm.content[index].title);
      }
    },
    setRelativeUrl(value, index) {
      this.relativeUrlEdited[index] = true;
      this.handleRelativeUrl(true, index, value);
    },
  },
};
</script>
